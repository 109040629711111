import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PERSONAL_DETAILS_DATA_ADAPTER } from './personal-details-data-adapter.token';
import { personalDetailsActions } from './personal-details.action';
import { catchError, filter, map, mergeMap, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { authContextFeatureActions } from '@ciphr/core/auth-context/state';
import { Router } from '@angular/router';

@Injectable()
export class PersonalDetailsEffects {
  private store = inject(Store);
  private actions$ = inject(Actions);
  private dataAdapter = inject(PERSONAL_DETAILS_DATA_ADAPTER);

  private router = inject(Router);

  loadPersonalDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(personalDetailsActions.load),
      mergeMap(() =>
        this.dataAdapter.fetchPersonalDetails().pipe(
          map((personalDetailsData) => personalDetailsActions.loadedSuccessfully(personalDetailsData)),
          catchError((httpResponseError) => {
            this.store.dispatch(personalDetailsActions.loadedFailed());
            return throwError(() => httpResponseError);
          }),
        ),
      ),
    ),
  );

  triggerPersonalDetailsLoadAfterCompanyContextChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authContextFeatureActions.selectActiveContext),
      filter(() => this.router.url.startsWith('/personal-details')),
      map(() => personalDetailsActions.load()),
    ),
  );
}
