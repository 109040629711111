import { createActionGroup, emptyProps } from '@ngrx/store';
import { PersonalDetails } from '@ciphr/domains/personal-details/models';

export const personalDetailsActions = createActionGroup({
  source: 'Personal Details',
  events: {
    'Load': emptyProps(),
    'Loaded Successfully': (personalDetailsData: PersonalDetails) => ({ personalDetailsData }),
    'Loaded Failed': emptyProps(),
    'Clear Data': emptyProps(),
  },
});
