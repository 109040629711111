import { createFeature, createReducer, on } from '@ngrx/store';
import { personalDetailsActions } from './personal-details.action';
import { PersonalDetails } from '@ciphr/domains/personal-details/models';

export interface PersonalDetailsState {
  data: PersonalDetails | null;
  loading: boolean;
}

export const initialState: PersonalDetailsState = {
  data: null,
  loading: false,
};

export const personalDetailsFeature = createFeature({
  name: 'personal-details',
  reducer: createReducer(
    initialState,
    on(personalDetailsActions.load, (state) => ({ ...state, loading: true })),
    on(personalDetailsActions.loadedSuccessfully, (state, { personalDetailsData }) => ({
      ...state,
      loading: false,
      data: personalDetailsData,
    })),
    on(personalDetailsActions.loadedFailed, (state) => ({
      ...state,
      loading: false,
    })),
    on(personalDetailsActions.clearData, (state) => ({ ...state, data: null })),
  ),
});
